<template>
    <div class="h-100 d-flex flex-column justify-content-center">
        <div>
            <img src="https://www.magico.pl/files/MaterialyDoPobrania/logo_magico_png_whitebackgroung_min.png"
                alt="Agencja Kreatywna Magico" width="200">
        </div>
        <div class="d-flex justify-content-between align-items-center">

            <h2>Grafik pracy</h2>
            Witaj {{ username }}
        </div>
        <div v-show="loading">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class=" hello pb-4">
            <ul v-show="!loading" v-for="v, ii in items" :key="ii" class="list-group">
                <template v-for="user in v">
                    <template v-for="item, it in user.table">
                        <li class="list-group-item d-flex justify-content-between py-3" :class="getDayClass(item)"
                            :key="it + user">
                            <div class=" text-start">{{ format(item.date) }} {{ item.dayname }} {{ today == item.date ? '[Dzisiaj]' : '' }}
                            </div>
                            <ul class="" v-if="item.events.length > 0">
                                <li class="" style="list-style: none;"
                                    v-for="ev, ki in item.events" :key="ki">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="badge me-2 mb-1" :style="'background-color:' + ev.group_color">{{
                                            ev.group }}</span> {{ ev.start_time.substr(0, 5) }} - {{ ev.end_time.substr(0,5) }}
                                    </div>
                                    <div class="form-control mt-2 p-0 fs-6" v-if="ev.comment">
                                        {{ ev.comment }}
                                    </div>
                                </li>
                            </ul>
                            <ul v-else>
                                <i v-if="item.urlop">Urlop</i>
                                <i v-else>Brak zmiany</i>
                            </ul>
                        </li>
                    </template>
                </template>
            </ul>
        </div>
    </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
    name: 'HOME',
    props: {
        msg: String
    },

    data: function () {
        return {
            items: [],
            username: '',
            loading: true,

        }
    },
    computed: {
        today: function () {
            return dayjs().format('YYYY-MM-DD')
        }
    },
    methods: {
        getDayClass: function (item) {
            if (this.today == item.date) return 'bg-success text-white';
            if (item.urlop) return 'bg-secondary text-white';
            if (item.events.length > 0) return 'bg-primary text-white';
        },
        format: function (date) {
            return dayjs(date).format('DD.MM')
        },
        loadData: function () {
            this.loading = true;
            this.$http.get('api/v2/grafik/me').then(response => {
                this.username = response.data.username;
                this.items = response.data.data;
                this.loading = false;
            });
        }

    },
    mounted() {
        this.loadData();
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.hello {
    overflow-y: auto;
    max-height: 100%;
    min-height: 50%;
}</style>